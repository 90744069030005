@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    color-scheme: light dark;
}

.high-contrast-div {
  @apply !bg-yellow-300 !text-black;
}

.nav-item > svg {
  @apply text-current w-6 h-6 lg:w-5 lg:h-5;
}

table {
  @apply w-full text-black dark:text-white dark:bg-gray-800 bg-slate-50 overflow-hidden self-center shadow-lg rounded-xl;
}

th {
  @apply font-normal;
}

th, td {
  @apply px-5 py-4;
}

thead {
  @apply text-sm font-normal text-gray-700 dark:text-white bg-slate-200 dark:bg-gray-700;
}

tr {
  @apply border-b border-slate-300 dark:border-gray-600;
}

tr:last-child {
  @apply border-b-0;
}

.subpages-main-div {
  @apply flex w-full justify-center px-5 lg:py-10 py-7 h-full;
}

.subpages-content-div {
  @apply flex flex-col max-w-4xl overflow-y-auto w-full min-h-96 dark:text-white;
}

.subpages-content-div h1 {
  @apply my-8 text-4xl font-semibold;
}
.subpages-content-div h2 {
  @apply my-8 text-3xl font-semibold;
}
.subpages-content-div h3 {
  @apply my-8 text-2xl font-semibold;
}

.subpages-content-div a {
  @apply text-blue-800 dark:text-blue-400 hover:underline;
}

.subpages-content-div table {
  @apply mb-7;
}

.custom-page {
  @apply flex flex-col max-w-4xl w-full h-full dark:text-white;
}

.subpages-content-div ul {
  @apply list-disc list-outside ml-10 mb-6;
}

.subpages-content-div li {
  @apply mb-1.5;
}

.subpages-p {
  @apply text-3xl font-semibold !mb-7 lg:!mb-10;
}

.link-main-div {
  @apply flex h-20 p-5 items-center justify-between rounded-lg cursor-pointer transition duration-300 hover:-translate-y-1 shadow-md;
}

.partner-div {
  @apply flex flex-col justify-center items-center w-full h-60 p-10 gap-10 rounded-lg shadow-xl border cursor-pointer hover:-translate-y-1 transition duration-300;
}

.article-preview img {
  @apply !hidden;
}

.dont-show-images img {
  @apply hidden;
}

.round-images img {
  @apply rounded-lg hover:brightness-75 transition;
}

.paragraph-margin p {
  @apply mb-6;
}

.menuTransition {
  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

/* WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.iframe-content iframe {
  @apply !w-full rounded-lg mb-4 md:!w-[750px] md:!h-[422px];
}

video {
  @apply w-auto h-[600px] rounded-lg m-auto;
}